import { sendGTMEvent } from "@next/third-parties/google";
import {
  callbotLandingPageUrl,
  signUpAdahubUrl,
} from "@/constants/landingPageUrls";

export const TagDataLayer = "tagDataLayer";

// Log Lead events
export const logSubmitLeadForm = (props: Record<string, any>) => {
  if (window.location.host === callbotLandingPageUrl) {
    sendGTMEvent({ event: "SUBMIT_CONTACT_FORM", value: props });
    window.fbq(
      "trackCustom",
      "SUCCESS_REGISTRATION",
      {
        ...props,
      },
      TagDataLayer
    );
    return;
  }
  if (
    window.location.pathname.includes("p/crm") ||
    window.location.host === signUpAdahubUrl
  ) {
    sendGTMEvent({ event: "ADAHUB_SUBMIT_CONTACT_FORM_CRM", value: props });
    window.fbq(
      "trackCustom",
      "SUCCESS_REGISTRATION",
      {
        ...props,
      },
      TagDataLayer
    );
  } else if (
    window.location.pathname.includes("/telesales") ||
    window.location.host === signUpAdahubUrl
  ) {
    sendGTMEvent({
      event: "ADAHUB_SUBMIT_CONTACT_FORM_TELESALES",
      value: props,
    });
    window.fbq(
      "trackCustom",
      "SUCCESS_REGISTRATION_TELESALES",
      {
        ...props,
      },
      TagDataLayer
    );
  } else {
    sendGTMEvent(
      { event: "ADAHUB_SUBMIT_CONTACT_FORM_CALLBOT", value: props },
      TagDataLayer
    );
    window.fbq("trackCustom", "SUCCESS_REGISTRATION", {
      ...props,
    });
  }
};

export const logSignUp = (props: Record<string, any> = {}) => {
  if (window.location.host.includes("adahub")) {
    sendGTMEvent({ event: "ADAHUB_SIGN_UP", value: props }, TagDataLayer);

    window.fbq("trackCustom", "SUCCESS_REGISTRATION", {
      ...props,
    });
  } else {
    sendGTMEvent({ event: "SIGN_UP", value: props });
    window.fbq(
      "trackCustom",
      "SUCCESS_REGISTRATION",
      {
        ...props,
      },
      TagDataLayer
    );
  }
};

export const logCallbotDemoRequest = (props: Record<string, any> = {}) => {
  sendGTMEvent({ event: "ADAHUB_CALLBOT_DEMO", value: props }, TagDataLayer);

  window.fbq("trackCustom", "SUCCESS_REGISTRATION", {
    ...props,
  });
};

export const logUleadDemoRequest = (message: string) => {
  sendGTMEvent({ event: "ulead_demo_request" }, TagDataLayer);
  // TODO: IMPLEMENT THIS
};

export const logSendDemoRequest = (message: string) => {
  sendGTMEvent({ event: "ulead_demo_request" }, TagDataLayer);
  // TODO: IMPLEMENT THIS
};

export const logOpenRegistration = () => {
  sendGTMEvent({ event: "open_registration" }, TagDataLayer);
  // TODO: IMPLEMENT THIS
};

export const logHeaderClick = (headerName: string) => {
  // TODO: IMPLEMENT THIS
};
